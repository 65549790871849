const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}` : "";
const RAILWAY_STATIC_URL = process.env.RAILWAY_STATIC_URL ? `https://${process.env.RAILWAY_STATIC_URL}` : "";
const HEROKU_URL = process.env.HEROKU_APP_NAME ? `https://${process.env.HEROKU_APP_NAME}.herokuapp.com` : "";
const RENDER_URL = process.env.RENDER_EXTERNAL_URL ? `https://${process.env.RENDER_EXTERNAL_URL}` : "";
export const WEBAPP_URL =
  process.env.NEXT_PUBLIC_WEBAPP_URL ||
  VERCEL_URL ||
  RAILWAY_STATIC_URL ||
  HEROKU_URL ||
  RENDER_URL ||
  "http://localhost:3000";
/** @deprecated use `WEBAPP_URL` */
export const BASE_URL = WEBAPP_URL;
export const WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL || "https://mygpt.fi";
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || "MyGPT.fi";
export const SUPPORT_MAIL_ADDRESS = process.env.NEXT_PUBLIC_SUPPORT_MAIL_ADDRESS || "help@mygpt.fi";
export const COMPANY_NAME = process.env.NEXT_PUBLIC_COMPANY_NAME || "MyGPT.fi, Inc.";
export const SENDER_ID = process.env.NEXT_PUBLIC_SENDER_ID || "MyGPT";
export const SENDER_NAME = process.env.NEXT_PUBLIC_SENDGRID_SENDER_NAME || "MyGPT.fi";

// This is the URL from which all mygpt Links and their assets are served.
// Use website URL to make links shorter(mygpt.fi and not app.mygpt.fi)
// As website isn't setup for preview environments, use the webapp url instead
export const CAL_URL = new URL(WEBAPP_URL).hostname.endsWith(".vercel.app") ? WEBAPP_URL : WEBSITE_URL;

export const IS_CALCOM =
  WEBAPP_URL &&
  (new URL(WEBAPP_URL).hostname.endsWith("mygpt.fi") ||
    new URL(WEBAPP_URL).hostname.endsWith("cal.dev") ||
    new URL(WEBAPP_URL).hostname.endsWith("cal-staging.com"));

export const CONSOLE_URL =
  new URL(WEBAPP_URL).hostname.endsWith(".cal.dev") ||
    new URL(WEBAPP_URL).hostname.endsWith(".cal-staging.com") ||
    process.env.NODE_ENV !== "production"
    ? `https://console.cal.dev`
    : `https://console.mygpt.fi`;
export const IS_SELF_HOSTED = !(
  new URL(WEBAPP_URL).hostname.endsWith(".cal.dev") || new URL(WEBAPP_URL).hostname.endsWith(".mygpt.fi")
);
export const EMBED_LIB_URL = process.env.NEXT_PUBLIC_EMBED_LIB_URL || `${WEBAPP_URL}/embed/embed.js`;
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const TRIAL_LIMIT_DAYS = 14;

export const HOSTED_CAL_FEATURES = process.env.NEXT_PUBLIC_HOSTED_CAL_FEATURES || !IS_SELF_HOSTED;

/** @deprecated use `WEBAPP_URL` */
export const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_WEBAPP_URL || `https://${process.env.VERCEL_URL}`;
export const LOGO = "/calcom-logo-white-word.svg";
export const LOGO_ICON = "/cal-com-icon-white.svg";
export const FAVICON_16 = "/favicon-16x16.png";
export const FAVICON_32 = "/favicon-32x32.png";
export const APPLE_TOUCH_ICON = "/apple-touch-icon.png";
export const MSTILE_ICON = "/mstile-150x150.png";
export const ANDROID_CHROME_ICON_192 = "/android-chrome-192x192.png";
export const ANDROID_CHROME_ICON_256 = "/android-chrome-256x256.png";
export const ROADMAP = "https://mygpt.fi/roadmap";
export const DESKTOP_APP_LINK = "https://mygpt.fi/download";
export const JOIN_DISCORD = "https://go.mygpt.fi/discord";
export const POWERED_BY_URL = `${WEBSITE_URL}/?utm_source=embed&utm_medium=powered-by-button`;
export const DOCS_URL = "https://mygpt.fi/docs";
export const DEVELOPER_DOCS = "https://developer.mygpt.fi";
export const SEO_IMG_DEFAULT = `${WEBSITE_URL}/og-image.png`;
// The Dynamic OG Image is passed through Next's Image API to further optimize it.
// This results in a 80% smaller image 🤯. It is however important that for the query
// parameters you pass to the /api/social/og/image endpoint, you wrap them in encodeURIComponent
// as well, otherwise the URL won't be valid.
export const SEO_IMG_OGIMG = `${CAL_URL}/_next/image?w=1200&q=100&url=${encodeURIComponent(
  "/api/social/og/image"
)}`;
export const SEO_IMG_OGIMG_VIDEO = `${WEBSITE_URL}/video-og-image.png`;
export const IS_STRIPE_ENABLED = !!(
  process.env.STRIPE_CLIENT_ID &&
  process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY &&
  process.env.STRIPE_PRIVATE_KEY
);
/** Self hosted shouldn't checkout when creating teams unless required */
export const IS_TEAM_BILLING_ENABLED = IS_STRIPE_ENABLED && (!IS_SELF_HOSTED || HOSTED_CAL_FEATURES);
export const FULL_NAME_LENGTH_MAX_LIMIT = 50;
export const MINUTES_TO_BOOK = process.env.NEXT_PUBLIC_MINUTES_TO_BOOK || "5";

// Needed for orgs
export const ALLOWED_HOSTNAMES = JSON.parse(`[${process.env.ALLOWED_HOSTNAMES || ""}]`) as string[];
export const RESERVED_SUBDOMAINS = JSON.parse(`[${process.env.RESERVED_SUBDOMAINS || ""}]`) as string[];

export const APP_CREDENTIAL_SHARING_ENABLED =
  process.env.CALCOM_WEBHOOK_SECRET && process.env.CALCOM_APP_CREDENTIAL_ENCRYPTION_KEY;

// Google Map API key for address valication
export const GOOGLE_MAP_API_KEY = "AIzaSyAZ3P9XEHmIMU2UAnfj0hCD2V2i6R3aStA";

// Subscription price
export const SUBSCRIPTION_PRICE: {
  [key: string]: {
    [key: string]: number;
  };
} = {
  FREE: {
    EUR: 0,
    USD: 0,
  },
  LEVEL1: {
    EUR: 15,
    USD: 16,
  },
  LEVEL2: {
    EUR: 25,
    USD: 27,
  },
  LEVEL3: {
    EUR: 499,
    USD: 542,
  },
};

interface SubscriptionDataType {
  advantageList: string[];
  price: { [key: string]: number };
}

export const SUBSCRIPTION_DATA: SubscriptionDataType[] = [
  {
    advantageList: [
      "your_expert_profile", "your_microcard", "your_online_calendar"
    ],
    price: SUBSCRIPTION_PRICE.FREEMIUM,
  },
  {
    advantageList: [
      "optimizes_expert_keywords",
      "connect_calendar_into_mygpt",
      "manage_experts",
      "meetings_payment",
      "lifetime_subscription",
    ],
    price: SUBSCRIPTION_PRICE.LEVEL1,
  },
  {
    advantageList: [
      "prolink", "clone_training", "expert_dashboard",
    ],
    price: SUBSCRIPTION_PRICE.LEVEL2,
  },
  {
    advantageList: [
      "mixtral_ai", "limit_of_10_gb_for_life", "ai_brain_for_life",
    ],
    price: SUBSCRIPTION_PRICE.LEVEL3,
  },
];
